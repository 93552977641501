import React from 'react'

interface BlogDefaultProps {
  sectionId: string
}

const BlogDefault: React.FC<BlogDefaultProps> = () => {
  return <div />
}

export default BlogDefault
