import React from 'react'
// Types
import { ArticlePreviewWithAuthorAndCategoriesProps } from '../../../types/blog'
// Components
import { Link } from 'gatsby'
import { Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Text, Image } from '../../../atoms'
import { ArticleAuthorsDate } from '../commons'
import { GatsbyImageBasicProps } from '../../../types/images'

interface BlogLandingHeroProps {
  article: ArticlePreviewWithAuthorAndCategoriesProps
  bgImage: GatsbyImageBasicProps
  bgColor: {
    hex: string
  }
}

const BlogLandingHero: React.FC<BlogLandingHeroProps> = ({ article, bgColor, bgImage }) => {
  return (
    <Flex
      position={'relative'}
      minHeight={[360, 540]}
      alignItems={'center'}
      p={[7, 8, 10]}
      bg={bgColor?.hex || 'purpleBlue'}
      borderRadius={'medium'}
      overflow={'hidden'}
    >
      {bgImage && (
        <Box
          display={['none', 'block']}
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
          height={'100%'}
          borderRadius={'medium'}
          overflow={'hidden'}
          zIndex={1}
        >
          <Image
            alt={''}
            draggable={'false'}
            image={bgImage.gatsbyImageData}
            style={{ width: '100%', height: '100%' }}
            imgStyle={{ objectFit: 'cover', objectPosition: 'left center' }}
          />
        </Box>
      )}
      <Box position={'relative'} zIndex={2}>
        <Row>
          <Col xs={12} sm={8} lg={5}>
            <Box mb={2}>
              {article.categories.map((c, i) => (
                <Link key={c.id} to={`/${c.pageInfo.slug}`}>
                  <Text as={'span'} color={'lightBlue'} fontSize={13} fontWeight={'semi'} textTransform={'uppercase'}>
                    {c.name}
                  </Text>
                  {i + 1 < article.categories.length && (
                    <Box display={'inline'} mx={1}>
                      <Text as={'span'} fontSize={13}>
                        {'•'}
                      </Text>
                    </Box>
                  )}
                </Link>
              ))}
            </Box>
            <Link to={`/${article.slug}`}>
              <Box>
                <Text
                  as={'h2'}
                  fontSize={['28px', '34px', '40px']}
                  lineHeight={['34px', '40px', '48px']}
                  fontWeight={'semi'}
                  fontFamily={'hoves'}
                  color={'white'}
                >
                  {article.title}
                </Text>
              </Box>
              <Box mt={4}>
                <Text color={'white'}>{article.excerpt}</Text>
              </Box>
            </Link>
            <Box mt={6}>
              <ArticleAuthorsDate
                light
                authors={article.authors}
                date={article.metaFormatted.firstPublishedAt || article.metaFormatted.createdAt}
              />
            </Box>
          </Col>
        </Row>
      </Box>
    </Flex>
  )
}

export default BlogLandingHero
