import React from 'react'
// Types
import { CategoryProps } from '../../../types/blog'
import { PageInfoProps } from '../../../types/pages'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading } from '../../../atoms'
import { CategoryLink } from '../../commons'

interface ArticlesHeaderWithCategoriesProps {
  title: string
  blogLandingPageInfo: PageInfoProps
  allLabel: string
  categories: CategoryProps[]
  isAll?: boolean
}

const ArticlesHeaderWithCategories: React.FC<ArticlesHeaderWithCategoriesProps> = ({
  title,
  blogLandingPageInfo,
  allLabel,
  categories,
  isAll = false,
}) => {
  return (
    <Box mt={[6, 6, 7]}>
      <Grid>
        <Row>
          <Col xs={12}>
            <Box>
              <Heading as={'h4'}>{title}</Heading>
            </Box>

            <Flex mt={[3, 4, 5]} alignItems={'center'} flexWrap={'wrap'}>
              <Box mt={3} mr={[6, 7, 8]}>
                <CategoryLink name={allLabel} pageInfo={blogLandingPageInfo} partiallyActive={isAll} />
              </Box>
              {categories.map((c, i) => (
                <Box key={c.id} mt={3} mr={i + 1 < categories.length ? [5, 6, 7] : undefined}>
                  <CategoryLink {...c} />
                </Box>
              ))}
            </Flex>
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

export default ArticlesHeaderWithCategories
