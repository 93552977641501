import React from 'react'
// Section Components list
import Default from './BlogDefault'
import BlogText from './BlogText'
import BlogQuote from './BlogQuote'
import BlogImage from './BlogImage'
import BlogFeaturedResource from './BlogFeaturedResource'
import BlogVideo from './BlogVideo'
import BlogPodcast from './BlogPodcast'

interface SectionBlogProps {
  key?: string | number
  sectionApiKey: string
  data: any
}

const SectionBlogComponents: { [key: string]: React.FC<any> } = {
  default: Default,
  s_blog_text: BlogText,
  s_blog_image: BlogImage,
  s_blog_quote: BlogQuote,
  s_blog_featured_resource: BlogFeaturedResource,
  s_blog_video: BlogVideo,
  s_blog_podcast: BlogPodcast,
}

const SectionBlog: React.FC<SectionBlogProps> = (props) => {
  const SectionComponent = SectionBlogComponents?.[props.sectionApiKey] || SectionBlogComponents.default
  return <SectionComponent {...props} />
}

export default SectionBlog
