import React from 'react'
// Types
import { ArticlePreviewWithAuthorAndCategoriesProps } from '../../../types/blog'
import { PaginationProps } from '../../../types/pages'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Heading, Text } from '../../../atoms'
import { Pagination } from '../../commons'
import { ArticleCard } from '../commons'

interface BlogPaginatedArticlesProps extends PaginationProps {
  title?: string
  articles: ArticlePreviewWithAuthorAndCategoriesProps[]
  prevLabel?: string
  nextLabel?: string
}

const BlogPaginatedArticles: React.FC<BlogPaginatedArticlesProps> = ({ title, articles, ...pagination }) => {
  return (
    <Box as={'section'} mb={[100, 120]} mt={title ? [100, 120] : [48, 64]}>
      <Grid>
        <Row>
          <Col xs={12}>
            {/* Title */}
            {title && (
              <Box mb={[0, 0, 6]}>
                <Heading as={'h4'}>{title}</Heading>
              </Box>
            )}
            {/* Articles */}
            {articles.length > 0 ? (
              <Row>
                {articles.map((art: ArticlePreviewWithAuthorAndCategoriesProps) => (
                  <Col key={art.id} xs={12} sm={6} md={4}>
                    <Box mb={[7, 8, 10]}>
                      <ArticleCard data={art} />
                    </Box>
                  </Col>
                ))}
              </Row>
            ) : (
              <Box minHeight={'400px'}>
                <Text fontSize={3} opacity={0.7} textAlign={'center'}>
                  No post
                </Text>
              </Box>
            )}
            {/* Pagination */}
            <Pagination {...pagination} />
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}
export default BlogPaginatedArticles
