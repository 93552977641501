import React from 'react'
// Types
import { BlogSectionImage } from '../../../../types/blog'
// Components
import { Box, Image, Text } from '../../../../atoms'

interface BlogImageProps {
  sectionApiKey: string
  data: BlogSectionImage
}

const BlogImage: React.FC<BlogImageProps> = ({ data }) => {
  const { image } = data

  return (
    <Box as={'figure'} textAlign={'center'} my={[7, 8, 9]}>
      <Image
        draggable={false}
        alt={image.alt || image.title || ''}
        image={image.gatsbyImageData}
        style={{ display: 'inline-block' }}
      />

      {image.title && (
        <Box as={'figcaption'} mt={3} px={[0, 0, 72, 144]}>
          <Text fontSize={13} fontWeight={'semi'} color={'darkShade'}>
            {image.title}
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default BlogImage
