import React from 'react'
// Types
import { PublicationFormattedProps } from '../../../types/pages'
import { AuthorProps, CategoryProps } from '../../../types/blog'
import { GatsbyImageBasicProps } from '../../../types/images'
// Components
import { Box, Flex, Image, Text } from '../../../atoms'
import ShareBar from '../ShareBar'
import { getUrlToShare } from '../../../utils/commons'
import { Link } from 'gatsby'

interface ArticleHeroProps {
  title: string
  authors: AuthorProps[]
  categories: CategoryProps[]
  metaFormatted: PublicationFormattedProps
  cover?: GatsbyImageBasicProps
}

const ArticleHero: React.FC<ArticleHeroProps> = (props) => {
  const { title, cover, authors, categories, metaFormatted } = props
  return (
    <Box as={'section'}>
      {!!cover && (
        <Box mb={7}>
          <Image
            draggable={false}
            alt={cover.alt || title}
            image={cover.gatsbyImageData}
            style={{ borderRadius: '24px', overflow: 'hidden' }}
          />
        </Box>
      )}
      <Flex mb={3} flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'center'}>
        <Box>
          {categories.map((c, i) => (
            <Link key={c.id} to={`/${c.pageInfo.slug}`}>
              <Text as={'span'} color={'purpleBlue'} fontSize={13} fontWeight={'semi'} textTransform={'uppercase'}>
                {c.name}
              </Text>
              {i + 1 < categories.length && (
                <Box display={'inline'} mx={1}>
                  <Text as={'span'} fontSize={13}>
                    {'•'}
                  </Text>
                </Box>
              )}
            </Link>
          ))}
        </Box>
        <Box flex={'0 0 auto'}>
          <Text as={'span'} fontSize={1} lineHeight={1.4}>
            {metaFormatted.firstPublishedAt || metaFormatted.createdAt}
          </Text>
        </Box>
      </Flex>
      <Box>
        <Text
          as={'h1'}
          fontSize={['28px', '34px', '40px']}
          fontWeight={'semi'}
          lineHeight={['34px', '40px', '48px']}
          fontFamily={'hoves'}
        >
          {title}
        </Text>
      </Box>
      <Flex justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} mt={[4, 5, 6]}>
        <Flex alignItems={'center'} flexWrap={'wrap'} pt={4} flex={['0 0 100%', '0 0 100%', '1 0 auto']} pr={4}>
          {authors.map((a) => (
            <Flex key={a.id} flex={'0 0 auto'} alignItems={'center'} mr={5} mb={4}>
              <Flex
                mr={3}
                flex={'0 0 auto'}
                justifyContent={'center'}
                alignItems={'center'}
                width={48}
                height={48}
                borderRadius={'circle'}
                overflow={'hidden'}
                bg={!authors[0].profilePicture ? 'dark' : undefined}
                zIndex={3}
              >
                {a.profilePicture ? (
                  <Image
                    draggable={false}
                    alt={a.profilePicture.alt || a.name}
                    image={a.profilePicture.gatsbyImageData}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      overflow: 'hidden',
                      borderRadius: '9999px',
                    }}
                    imgStyle={{ borderRadius: '9999px' }}
                  />
                ) : (
                  <Box position={'relative'} width={'100%'} height={'100%'} overflow={'hidden'}>
                    <Box
                      position={'absolute'}
                      width={16}
                      height={16}
                      top={'9px'}
                      left={'50%'}
                      borderRadius={'circle'}
                      bg={'white'}
                      opacity={0.32}
                      style={{
                        transform: 'translateX(-50%)',
                      }}
                    />
                    <Box
                      position={'absolute'}
                      width={38}
                      height={48}
                      top={'30px'}
                      left={'50%'}
                      borderRadius={'circle'}
                      bg={'white'}
                      opacity={0.32}
                      style={{
                        transform: 'translateX(-50%)',
                      }}
                    />
                  </Box>
                )}
              </Flex>
              <Box>
                <Link to={`/${a.pageInfo.slug}`}>
                  <Text as={'span'} fontSize={'inherit'} fontWeight={'semi'} lineHeight={'inherit'} color={'dark'}>
                    {a.name}
                  </Text>
                </Link>
              </Box>
            </Flex>
          ))}
        </Flex>
        <Box flex={'0 0 100%'} pt={4} display={['block', 'block', 'none']}>
          <ShareBar urlToShare={getUrlToShare()} direction={'row'} />
        </Box>
      </Flex>
    </Box>
  )
}

export default ArticleHero
