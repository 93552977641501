import React from 'react'
// Types
import { BlogSectionVideo } from '../../../../types/blog'
// Components
import { Box, Text, VideoElement } from '../../../../atoms'

interface BlogVideoProps {
  sectionApiKey: string
  data: BlogSectionVideo
}

const BlogVideo: React.FC<BlogVideoProps> = ({ data }) => {
  const { video, backgroundColor } = data

  return (
    <Box my={[7, 8, 9]}>
      {video && (
        <Box p={[4, 5, 6]} borderRadius={'large'} backgroundColor={backgroundColor?.hex || 'white'}>
          <VideoElement {...video} />
          {video.title && (
            <Box mt={[3, 3, 4]}>
              <Text textAlign={'center'} fontSize={2}>
                {video.title}
              </Text>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

export default BlogVideo
