import React from 'react'
// Types
import { BlogSectionFeaturedResource } from '../../../../types/blog'
// Components
import { Flex, Box, Image, Text, Button } from '../../../../atoms'

interface BlogFeaturedResourceProps {
  sectionApiKey: string
  data: BlogSectionFeaturedResource
}

const BlogFeaturedResource: React.FC<BlogFeaturedResourceProps> = ({ data }) => {
  const { resource, buttonLabel, backgroundImage, backgroundColor } = data
  return (
    <Box
      position={'relative'}
      my={[7, 8, 9]}
      bg={backgroundColor.hex || 'purple'}
      borderRadius={'regular'}
      overflow={'hidden'}
    >
      {backgroundImage && (
        <Box position={'absolute'} top={0} left={0} width={'100%'} height={'100%'} zIndex={1}>
          <Image
            draggable={false}
            alt={''}
            image={backgroundImage.gatsbyImageData}
            style={{ overflow: 'hidden', borderRadius: '16px', width: '100%', height: '100%' }}
            imgStyle={{ borderRadius: '16px' }}
          />
        </Box>
      )}
      <Flex position={'relative'} alignItems={'center'} px={[5, 6, 8]} zIndex={2}>
        {resource.cover && (
          <Box display={['none', 'block']} flex={'0 0 140px'} mr={[4, 5, 7]} style={{ transform: 'translateY(36px)' }}>
            <Image
              draggable={false}
              alt={resource.cover.alt || resource.title}
              image={resource.cover.gatsbyImageData}
            />
          </Box>
        )}
        <Box flex={'1'} py={[5, 6, 7]}>
          <Text
            fontFamily={'hoves'}
            fontWeight={'semi'}
            fontSize={['28px', '28px', '28px']}
            lineHeight={['34px', '34px', '34px']}
            color={'white'}
          >
            {resource.title}
          </Text>

          {!resource.comingSoon && (
            <Box mt={4}>
              <Button buttonType={'text'} color={'white'} link={resource.pageInfo}>
                {buttonLabel}
              </Button>
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export default BlogFeaturedResource
