import React from 'react'
// Types
import { BlogSectionPodcast } from '../../../../types/blog'
// Components
import { Box } from '../../../../atoms'

interface BlogPodcastProps {
  sectionApiKey: string
  data: BlogSectionPodcast
}

const BlogPodcast: React.FC<BlogPodcastProps> = ({ data }) => {
  const { iframeUrl } = data

  return (
    <Box my={[7, 8, 9]}>
      {!!iframeUrl && <iframe width={'100%'} frameBorder={'no'} scrolling={'no'} seamless src={iframeUrl} />}
    </Box>
  )
}

export default BlogPodcast
