import React from 'react'
// Types
import { BlogSectionQuote } from '../../../../types/blog'
// Components
import { Box, Text } from '../../../../atoms'

interface BlogQuoteProps {
  sectionApiKey: string
  data: BlogSectionQuote
}

const BlogQuote: React.FC<BlogQuoteProps> = ({ data }) => {
  const { quote, author } = data
  return (
    <Box my={[7, 8, 9]}>
      <Box width={'100%'} height={1} bg={'dark'} opacity={0.16} />
      <Box as={'blockquote'} py={[7, 8, 9]}>
        <Text
          color={'purpleBlue'}
          fontSize={['24px', '24px', '28px']}
          fontWeight={'semi'}
          lineHeight={['32px', '34px', '34px']}
          fontFamily={'hoves'}
        >
          {quote}
        </Text>

        {!!author && (
          <Box mt={3}>
            <Text fontSize={1} fontWeight={'semi'} color={'lightNavy'}>
              {author}
            </Text>
          </Box>
        )}
      </Box>
      <Box width={'100%'} height={1} bg={'dark'} opacity={0.16} />
    </Box>
  )
}

export default BlogQuote
