import React from 'react'
//Components
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Box, Flex, Image, SocialButton, Text } from '../../../atoms'
//Types
import { AuthorProps } from '../../../types/blog'

interface AuthorHeroProps {
  author: AuthorProps
  totalArticles: number
  articleNumberLabelSingular: string
  articleNumberLabelPlural: string
}

const AuthorHero: React.FC<AuthorHeroProps> = (props) => {
  const { author, totalArticles, articleNumberLabelSingular, articleNumberLabelPlural } = props

  return (
    <Box as={'section'} pt={[100, 120]}>
      <Grid>
        <Row>
          <Col xs={12}>
            <Box p={8} bg={'purpleBlueLight'} borderRadius={'medium'}>
              <Box
                mx={'auto'}
                mb={5}
                width={96}
                height={96}
                borderRadius={'circle'}
                overflow={'hidden'}
                bg={!author.profilePicture ? 'dark' : undefined}
              >
                {author.profilePicture ? (
                  <Image
                    draggable={false}
                    alt={author.profilePicture.alt || author.name}
                    image={author.profilePicture.gatsbyImageData}
                    style={{ borderRadius: '9999px', overflow: 'hidden' }}
                  />
                ) : (
                  <Box position={'relative'} width={'100%'} height={'100%'} overflow={'hidden'}>
                    <Box
                      position={'absolute'}
                      width={32}
                      height={32}
                      top={'16px'}
                      left={'50%'}
                      borderRadius={'circle'}
                      bg={'white'}
                      opacity={0.32}
                      style={{
                        transform: 'translateX(-50%)',
                      }}
                    />
                    <Box
                      position={'absolute'}
                      width={72}
                      height={72}
                      top={'56px'}
                      left={'50%'}
                      borderRadius={'circle'}
                      bg={'white'}
                      opacity={0.32}
                      style={{
                        transform: 'translateX(-50%)',
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <Text as={'h2'} fontSize={4} fontWeight={'semi'} textAlign={'center'}>
                  {author.name}
                </Text>
                <Text fontSize={2} textAlign={'center'}>
                  {!!author.role && `${author.role}   •   `}
                  {totalArticles} {totalArticles < 2 ? articleNumberLabelSingular : articleNumberLabelPlural}
                </Text>
                {author.socials && author.socials.length > 0 && (
                  <Flex justifyContent={'center'} mt={[3, 4, 5]}>
                    {author.socials.map((s) => (
                      <Box key={s.id} mx={4} flex={'0 0 auto'}>
                        <SocialButton {...s} />
                      </Box>
                    ))}
                  </Flex>
                )}
              </Box>
            </Box>
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

export default AuthorHero
