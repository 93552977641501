import React, { useMemo } from 'react'
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
// import { prism as codeStyle } from 'react-syntax-highlighter/dist/esm/styles/prism'
// Types
import { BlogSectionText } from '../../../../types/blog'
// Components
import { Box, Text } from '../../../../atoms'
import ContentMd from '../../../commons/ContentMd'

interface BlogTextProps {
  sectionApiKey: string
  data: BlogSectionText
}

const BlogText: React.FC<BlogTextProps> = ({ data }) => {
  const { content } = data

  const renderers = useMemo(() => {
    return {
      p: (props: any) => (
        <Box mt={5} mb={6}>
          <Text {...props} color={'dark'} />
        </Box>
      ),
      h1: ({ level, ...otherProps }: any) => (
        <Box mt={8} mb={6}>
          <Text
            as={'h2'}
            fontSize={['28px', '32px', '32px']}
            fontWeight={'semi'}
            lineHeight={['32px', '40px', '40px']}
            fontFamily={'hoves'}
            {...otherProps}
          />
        </Box>
      ),
      h2: ({ level, ...otherProps }: any) => (
        <Box mt={8} mb={6}>
          <Text
            as={'h2'}
            fontSize={['24px', '28px', '28px']}
            fontWeight={'semi'}
            lineHeight={['32px', '34px', '34px']}
            fontFamily={'hoves'}
            {...otherProps}
          />
        </Box>
      ),
      h3: ({ level, ...otherProps }: any) => (
        <Box mt={8} mb={6}>
          <Text
            as={'h3'}
            fontSize={['20px', '24px', '24px']}
            fontWeight={'semi'}
            lineHeight={['24px', '32px', '32px']}
            fontFamily={'hoves'}
            {...otherProps}
          />
        </Box>
      ),
      h4: ({ level, ...otherProps }: any) => (
        <Box mt={8} mb={6}>
          <Text
            as={'h4'}
            fontSize={['20px', '24px', '24px']}
            lineHeight={['24px', '32px', '32px']}
            fontFamily={'hoves'}
            color={'navy'}
            {...otherProps}
          />
        </Box>
      ),
      h5: ({ level, ...otherProps }: any) => (
        <Box mt={6} mb={4}>
          <Text
            as={'h5'}
            fontSize={['18px', '20px', '20px']}
            fontWeight={'semi'}
            lineHeight={['20px', '24px', '24px']}
            fontFamily={'hoves'}
            {...otherProps}
          />
        </Box>
      ),
      h6: ({ level, ...otherProps }: any) => (
        <Box mt={6} mb={4}>
          <Text
            as={'h5'}
            fontSize={['18px', '20px', '20px']}
            lineHeight={['20px', '24px', '24px']}
            fontFamily={'hoves'}
            color={'navy'}
            {...otherProps}
          />
        </Box>
      ),
      blockquote: (props: any) => (
        <Box as={'blockquote'} mt={5} mb={6} px={5} borderLeft={'3px solid'} borderColor={'purpleBlue'} {...props} />
      ),
      // TODO: refactor since react-markdown use `code` for inline code & code block
      // code: (props: any) => {
      //   const { language, value } = props
      //   return (
      //     <Box my={6}>
      //       {/*<SyntaxHighlighter language={language} style={codeStyle}>*/}
      //       {/*  {value}*/}
      //       {/*</SyntaxHighlighter>*/}
      //     </Box>
      //   )
      //},
    }
  }, [])
  return (
    <Box my={[7, 8, 9]}>
      {!!content && (
        <Box mt={5}>
          <ContentMd content={content} color={'dark'} dotColor={'purpleBlue'} renderers={renderers} />
        </Box>
      )}
    </Box>
  )
}

export default BlogText
